.filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}

.btn {
  margin-right: 0.4em
}

.section {
  display: flex;
  justify-content: center;
  //border: 2px solid #CDAEF3;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  background: white !important;
  margin: 5px;
}

img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

@primary-color: #1890ff;