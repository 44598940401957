.listView {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .inputBox {
    margin: 5px;
    width: 300px;
    cursor: pointer;
  }

  .boxLabel {
    margin-left: 5px;
    background-color: white;
    z-index: 9;
    padding: 10px;
    position: relative;
    top: 10px;
    width: 120px;

  }

  .inputBoxValue {
    border: 1px solid #ddd;
    padding: 15px 15px 10px;
    position: relative;
    top: -10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@primary-color: #1890ff;