@primary: #1890ff;
@success: #5cb85c;
@info: #5bc0de;
@warning: #f0ad4e;
@danger: #d9534f;
@basic: #f1f1f1;

:global {
  .mt10 {
    margin-top: 10px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .mb40 {
    margin-bottom: 40px;
  }

  .mr10 {
    margin-right: 10px;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-thead {
    background: #e9eef1;
  }

  .ant-btn {
    //border-radius: 0;
  }

  .newForm {

  }

  .btnGroup {
    button {
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }

  .ant-select {
    width: 100%;
  }

  .ant-calendar-picker {
    width: 100%;
  }

  .textAlignR {
    text-align: right;
  }

  .businessLabel {
    padding: 5px;
    font-size: 17px;
    color: white;
    border: 1px solid #ddd3;
    margin: 5px 5px 0;
    text-align: center;
    cursor: pointer;
  }

  table {
    tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  }

  /* .ant-select-dropdown-menu-item-active {
     background-color: #48c75b!important;
   }*/

  .h20 {
    height: 20px;
  }

  .rowFlex {
    display: flex;

    button {
      margin-left: 10px;
    }

    .d1 {
      width: 100%;

      &.mr10 {
        margin-right: 10px;
      }
    }

    .d2 {
      width: 100%;
      margin-left: 10px;
    }
  }

  .customCard {
    .ant-card-body {
      margin-bottom: 100px;
    }

    .ant-card-actions {
      position: sticky;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #e8e8e8;
      z-index: 99;
    }
  }

  .alternateTable {
    table {
      tbody {
        tr:nth-child(odd) {
          background: white;
        }

        tr:nth-child(even) {
          background: #f9f9f9;
        }
      }
    }
  }

  .alignCenter {
    text-align: center;
    cursor: pointer;
  }

  .alignRight {
    text-align: right;
  }

  .padding0 {
    .ant-collapse-content-box {
      padding: 10px 0 0;
    }
  }

  .mainLoader {
    display: flex;
    height: 100%;
    width: 100%;
    background: #ffffffc7;
    z-index: 9999;
    position: fixed;
    left: 0;
    justify-content: center;
    align-items: center;
    font-size: 50px;

    i {
      margin-right: 20px;
    }
  }

  .actionGroup {
    a {
      margin-right: 5px;
    }

    button {
      margin-right: 5px;
    }
  }

  .linkBtn {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    touch-action: manipulation;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 10px;
  }

  /*.ant-btn-success {
    background-color: #6C18A4;
    color: white;
    border: 1px solid #6C18A4;

    &:hover {
      opacity: 0.8;
      background-color: #6C18A4;
      color: white;
      border: 1px solid #6C18A4;
    }
  }
*/

  .ant-tag {
    height: 24px;
    padding: 2px 7px;
    font-size: 13px;
    border-radius: 4px;
  }

  .font10 {
    font-size: 10px;
  }

  .smBtn {
    font-size: 10px;
    line-height: 1.499;
    position: relative;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    touch-action: manipulation;
    height: 25px;
    padding: 0 10px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 10px;
    margin-bottom: 5px;
  }

  .ewayInfo {
    font-size: 16px;
    color: @primary;

    .ant-row {
      margin-top: 10px;

      .ant-col-12:last-child {
        text-align: right;
      }
    }

  }

  .ant-btn-success {
    color: #fff;
    background-color: @success;
    border-color: #4cae4c;
  }

  .ant-btn-info {
    color: #fff;
    background-color: @info;
    border-color: #46b8da;
  }

  .ant-btn-warning {
    color: #fff;
    background-color: @warning;
    border-color: #eea236;
  }

  .ant-btn-danger {
    color: #fff;
    background-color: @danger;
    border-color: #d43f3a;
  }

  .ant-btn-basic {
    color: #333;
    background-color: @basic;
    border-color: #f7ebeb;
  }

  .ant-input-number {
    width: 100%;
  }

  .inputLabel {
    align-items: center;
    justify-content: flex-end;
    height: 32px;
    display: flex;
    padding-right: 20px;
  }

  .rowFlex {
    display: flex;

    > div {
      width: 100%;
    }

    button {
      margin-left: 10px;
    }

    .d1 {
      width: 100%;

      &.mr10 {
        margin-right: 10px;
      }
    }

    .d2 {
      width: 100%;
      margin-left: 10px;
    }
  }

}

@primary-color: #1890ff;