.outerDiv {
  margin-top: 10px;

  .headerDiv {
    display: flex;
    background: #e9eef1;

    div {
      width: 33.33%;
      padding: 5px 10px;

      &:nth-child(even) {
        text-align: right;
      }
    }

  }

  .mainDiv {
    display: flex;

    &:nth-child(odd) {
      background: #EAF7D5;
    }

    &:nth-child(even) {
      background: #F0F0F0;
    }

    &:first-child {
      border-top: 1px solid #dddddd69;
    }

    div {
      width: 15%;
      text-align: right;
      border-right: 1px solid #dddddd69;
      border-bottom: 1px solid #dddddd69;
      line-height: 1em;
      padding: 2px 10px;

      &:first-child {
        width: 55%;
        text-align: left;
        border-left: 1px solid #dddddd69;
      }


    }

  }
}

.bSize {
  font-size: 15px;
  font-weight: bold;
}

.sSize {
  font-size: 11px;
  color: gray;
}

.balanceField {
  font-size: 16px;
  position: absolute;
  right: 15px;
  top: -6px;
  color: green;
  font-weight: bold;
}

@primary-color: #1890ff;