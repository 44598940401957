:global {
  color: black;

  .box {
    font-size: 15px;

    h2 {
      padding: 5px 5px;
    }

    .evenColor {
      background: #EAF7D5;
    }

    .oddColor {
      background: #F0F0F0;
    }

    .ant-row {
      .ant-col-8:last-child {
        text-align: right;
      }

      .ant-col-8:nth-child(2) {
        text-align: right;
      }

      &.grayRow {
        color: #A3A2AC;
        font-size: 13px;
      }

      .borderV {
        border-top: 1px solid;
        border-bottom: 1px solid;
        margin-left: 50px;
      }
    }
  }
}

@primary-color: #1890ff;